<template>
  <section class="pb-4 container">
    <GoBack />
    <Breadcrumb class="sobre_section_bc-color">
      <template v-slot:breadcrumbLinks>
        <router-link
          tag="p"
          :to="{ name: link.href }"
          v-for="(link, idx) in breadcrumbItems"
          :key="idx"
        >
          {{ link.text }}
        </router-link>
      </template>
    </Breadcrumb>
    <v-row>
      <v-col class="d-flex justify-center align-center mt-3 ml-3">
        <FlagTitle title="Formulário" />
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center align-center mb-5 flex-md-row-reverse">
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center align-center mx-auto"
      >
        <div class="section__container_card">
          <Card
            title_alignment="center"
            borderColor="#C92F05"
            titleColor="#C92F05"
          >
            <template v-slot:conteudo>
              <p>
                É a partir do formulário que se inicia o processo de
                cadastramento das pessoas atingidas com o levantamento de suas
                perdas e seus danos. O formulário é a fonte primária dos demais
                instrumentos complementares. Trata-se de um documento extenso,
                pois buscou-se contemplar o maior rol de possíveis perdas e
                danos causados em consequência do rompimento, que pode conter
                até 4.000 perguntas a depender dos campos pertinentes de serem
                abordados para cada núcleo familiar, subdivididas em quatro
                grandes eixos temáticos:
              </p>
              <ul>
                <li>
                  <strong>Eixo 1 -</strong> Bens materiais: documentos pessoais,
                  terrenos, infraestruturas, plantações, edificações principais
                  e acessórias, benfeitorias, bens domésticos e pessoais e
                  veículos;
                </li>
                <li>
                  <strong>Eixo 2 -</strong> Atividades econômicas: trabalhos
                  assalariados, pesca, atividades agrícolas, pecuárias,
                  comerciais, serviços, atividades autônomas, entre outras
                  fontes de renda;
                </li>
                <li>
                  <strong>Eixo 3 -</strong> Bens coletivos: escolas, postos de
                  saúde, templos religiosos, correios, iluminação pública,
                  espaços de lazer, esgoto e calçamento;
                </li>
                <li>
                  <strong>Eixo 4 -</strong> Bens imateriais e danos morais: uso
                  do tempo, festejos populares, perda do rio, perdas humanas,
                  processos de adoecimento físico e mental, sofrimentos, dentre
                  outros.
                </li>
              </ul>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/formacao_equipe.png"
            alt=""
          />
          <p style="font-size: 0.5em">
            Formação da equipe do Cadastro. Acervo Cáritas, 2018.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center mb-5">
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center align-center mx-auto mb-3"
      >
        <div class="section__container_card">
          <Card borderColor="#C92F05" titleColor="#C92F05">
            <template v-slot:conteudo>
              <p>
                Para a realização da primeira etapa do processo de
                cadastramento, em janeiro de 2018, foram contratados, pela
                Cáritas, profissionais das áreas de Serviço Social, Ciências
                Sociais e Ciências Ambientais, com atuação voltada para a
                acolhida humanizada, a escuta ativa e a observação criteriosa
                das questões sociais e de todas as suas múltiplas expressões.
              </p>
              <p>
                Formação da equipe do Cadastro. Acervo Cáritas, 2018. Nos
                primeiros meses, as aplicações davam-se em locais comunitários
                cedidos ou nas residências provisórias das famílias, depois
                passaram a ser realizadas prioritariamente no espaço do
                escritório da Cáritas. Foram cadastradas famílias e entidades
                pertencentes às comunidades rurais: Bento Rodrigues, Paracatu de
                Baixo, Borba, Pedras, Campinas, Ponte do Gama, Paracatu de Cima,
                Camargos, Monsenhor Horta, Águas Claras, Bicas, Goiabeiras, Mata
                do Chaves, Santa Rita e Braúnas.
              </p>
              <p>
                Ressalta-se que, ainda que o formulário apresente um grande
                volume de perguntas, esse método não é capaz de levantar todas
                as perdas e danos sofridos. Devido às limitações do formulário,
                foi necessária a criação de instrumentos complementares para
                garantir todas as possibilidades de declarações dos danos
                acarretados pelo rompimento da barragem de Fundão. Assim sendo,
                a aplicação do formulário é sucedida por: cartografia social
                familiar (Etapa 2); vistoria (inspeção nos terrenos atingidos -
                Etapa 3); e tomada de termo (levantamento individual de danos
                morais e imateriais - Etapa 4). As metodologias desenvolvidas no
                âmbito do cadastro e orientadas por profissionais capacitados
                permitem a percepção e o registro da sofisticação da oralidade
                dos sujeitos em questão, que apresentam um vasto repertório em
                suas descrições e, muitas vezes, constroem, por meio de suas
                falas, ricas imagens e metáforas. A experiência de comunicação,
                portanto, é bastante qualificada.
              </p>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <v-img max-width="70%" src="../../assets/aplicadoras.png" alt="" />
          <p style="font-size: 0.5em; max-width: 70%">
            Aplicadoras organizando o material para a realização da primeira
            semana de formulário. Foto: Acervo Cáritas, 2018.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row cols="12" class="d-flex justify-center align-center mb-5 pa-0">
      <v-col cols="8" class="d-flex flex-column justify-center align-center">
        <Card borderColor="#C92F05">
          <template v-slot:conteudo>
            <p>
              Posteriormente, foi inserida a Etapa 5, com a sistematização dos
              danos. Para melhor compreensão das etapas constitutivas do
              processo de cadastramento na Cáritas em Mariana-MG, segue um
              diagrama com o intuito de ilustrar e resumir todas as etapas
              realizadas:
            </p>
          </template>
        </Card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center align-center form__image mb-5">
        <v-img
          max-width="70%"
          src="../../assets/formulario_image_background.png"
          alt=""
        />
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center mb-5">
      <div class="d-flex flex-column justify-center align-center">
        <v-btn
          :to="{ name: 'sobre_cartografia_social_familiar' }"
          small
          color="#E06919"
          class="white--text pa-5"
        >
          Conhecer "Cartografia Social Familiar"
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-row>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import FlagTitle from "../../components/FlagTitle.vue";
import GoBack from "@/components/GoBack.vue";

export default {
  components: { Card, Breadcrumb, FlagTitle, GoBack },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "<       Quem somos       /",
          href: "cards",
        },
        {
          text: "      Assessoria Técnica      /",
          href: "sobre_assessoria_tecnica",
        },
        {
          text: "O Cadastro /",
          href: "sobre_cadastro",
        },
        {
          text: "Formulário",
          href: "sobre_reformulacao_cadastro",
        },
      ],
    };
  },
};
</script>

<style scoped>
ul {
  display: flex;
  flex-direction: column;
}
ul,
li {
  font-size: 0.8em;
  margin-bottom: 1.5em;
}

.form__image img {
  max-width: 70%;
}
.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.section__container_card {
  max-width: 40em;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.assessoria_card {
  padding: 2em;
}

.assessoria_card p {
  padding: 1em;
  text-align: center;
  font-size: 0.7em;
}

.assessoria__items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 7em;
}

.assessoria__items p {
  text-align: center;
  font-size: 0.6em;
  max-width: 80%;
}
</style>
